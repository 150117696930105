export const orderedWorkList = {
  work1: {
    order: 1,
    title: "Arena Gliwice",
    to: "/realizacje/ukonczone/arena-gliwice",
  },
  work2: {
    order: 2,
    title: "MCK",
    to: "/realizacje/ukonczone/mck",
  },
  work3: {
    order: 3,
    title: "Wroclavia",
    to: "/realizacje/ukonczone/wroclavia",
  },
  work4: {
    order: 4,
    title: "Stadion Zabrze",
    to: "/realizacje/ukonczone/stadion-zabrze",
  },
  work5: {
    order: 5,
    title: "Zespół pieśni i tańca śląsk",
    to: "/realizacje/ukonczone/zespol-piesni-i-tanca-slask",
  },
  work6: {
    order: 6,
    title: "Face 2 Face",
    to: "/realizacje/ukonczone/face-2-face",
  },
}
